<script setup lang="ts">
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { FetchError } from "ofetch";
import nodeRenderers from "~/lib/node-renderers";
import type { TypeUser } from "~/types/auth";
import type { TypePage } from "~/types/contentful";

const page = useLoadedContent<TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">>();

const errorMessage = ref("");

const form = ref<HTMLFormElement>();

const pending = ref(false);

const email = ref("");

const emailError = ref("");

const password = ref("");

const passwordError = ref("");

const passcodeSent = ref("");

const usePasscode = ref(false);

const loggedIn = ref(false);

const passcode = reactive(Array<string>(6));

watchEffect(() => {
  passcode.forEach((char, index) => (passcode[index] = char.toUpperCase()));
});

const passcodeInput = (event: Event) => {
  ((event.target as HTMLInputElement).nextSibling as HTMLInputElement | null)?.focus();

  if (checkPasscode()) {
    login(event, true);
  }
};

const passcodePaste = (event: ClipboardEvent) => {
  const code = event.clipboardData?.getData("text").toUpperCase();

  if (!code) {
    return;
  }

  let input = event.target as HTMLInputElement | null;

  for (const char of code) {
    if (!input) break;
    input.value = char;
    input = input.nextSibling as HTMLInputElement | null;
  }

  if (checkPasscode()) {
    login(event, true);
  }
};

const checkPasscode = () => passcode.filter(Boolean).length === 6;

const login = (_event: Event, loginPasscode = false) => {
  if (!form.value?.reportValidity()) {
    return;
  }

  errorMessage.value = "";

  pending.value = true;

  $fetch<string | TypeUser>("/api/auth/login", {
    method: "POST",
    body: {
      "email": email.value,
      "password": password.value,
      ...(passcode.length
        ? Object.fromEntries(
            passcode.filter(Boolean).map((item, index) => [`passcode${String(index)}`, item]),
          )
        : {}),
      loginPasscode,
      "loginPassword": !!password.value && !loginPasscode,
      "cf-turnstile-response": turnstileToken.value,
    },
  })
    .then(async (data) => {
      if (data === "login-passcode-sent") {
        passcodeSent.value = email.value;

        nextTick(() => document.querySelector<HTMLInputElement>("[name=passcode0]")?.focus());

        usePasscode.value = true;
      } else if (typeof data !== "string") {
        useUser().value = data;

        loggedIn.value = true;

        // Clear inputs to stop them reappearing after logout
        const emailInput = email.value;

        if (emailInput) email.value = "";

        const passwordInput = password.value;

        if (passwordInput) password.value = "";

        await navigateTo(useRouter().options.history.state.back?.toString() ?? "/");
      }

      turnstile.value?.reset();

      pending.value = false;
    })
    .catch((error: unknown) => {
      if (error instanceof FetchError) {
        errorMessage.value = error.data.message;
      }

      turnstile.value?.reset();

      pending.value = false;
    });
};

const turnstile = ref();

const turnstileToken = ref("");

onMounted(() =>
  setTimeout(() => {
    if (useScriptCloudflareTurnstile().status.value === "error") {
      errorMessage.value
        = "Your browser does not support bot detection. Please whitelist challenges.cloudflare.com or try another browser.";
    }
  }, 2000),
);
</script>

<template>
  <UiContainer
    v-if="page && !loggedIn"
    class="flex h-[calc(100vh-94px)] min-h-[900px] items-center justify-center bg-blue/10"
  >
    <div class="m-auto w-full max-w-[482px]">
      <div class="flex flex-col items-center">
        <h1 class="mb-4 font-bold">
          {{ page.fields.title }}
        </h1>
        <div
          v-if="page.fields.content"
          class="mb-7"
        >
          <RichTextRenderer
            :document="page.fields.content"
            :node-renderers="nodeRenderers"
          />
        </div>
        <p
          v-if="errorMessage"
          class="rounded bg-[orange] px-2 text-white"
        >
          {{ errorMessage }}
        </p>
      </div>
      <form
        ref="form"
        @submit.prevent="login"
      >
        <div class="my-6 flex flex-col gap-3">
          <UiFormInput
            v-if="!usePasscode || !passcodeSent"
            v-model="email"
            v-model:error="emailError"
            type="email"
            title="Email"
            placeholder="Email"
            required
          />
          <UiFormInput
            v-if="!usePasscode"
            v-model="password"
            v-model:error="passwordError"
            type="password"
            title="Password"
            placeholder="Password"
            required
          />
        </div>
        <template v-if="passcodeSent">
          <div class="mb-8 flex flex-col justify-center gap-4 text-center">
            <div>Please enter the passcode sent to {{ passcodeSent }}</div>
            <input
              type="hidden"
              name="email"
              :value="passcodeSent"
            />
            <div class="flex justify-center gap-3">
              <input
                name="passcode0"
                type="tel"
                class="size-12 text-center text-4xl font-bold uppercase"
                maxlength="1"
                required
                @input="passcodeInput"
                @paste="passcodePaste"
              />
              <input
                name="passcode1"
                type="tel"
                class="size-12 text-center text-4xl font-bold uppercase"
                maxlength="1"
                required
                @input="passcodeInput"
                @paste="passcodePaste"
              />
              <input
                name="passcode2"
                type="tel"
                class="size-12 text-center text-4xl font-bold uppercase"
                maxlength="1"
                required
                @input="passcodeInput"
                @paste="passcodePaste"
              />
              <input
                name="passcode3"
                type="tel"
                class="size-12 text-center text-4xl font-bold uppercase"
                maxlength="1"
                required
                @input="passcodeInput"
                @paste="passcodePaste"
              />
              <input
                name="passcode4"
                type="tel"
                class="size-12 text-center text-4xl font-bold uppercase"
                maxlength="1"
                required
                @input="passcodeInput"
                @paste="passcodePaste"
              />
              <input
                name="passcode5"
                type="tel"
                class="size-12 text-center text-4xl font-bold uppercase"
                maxlength="1"
                required
                @input="passcodeInput"
                @paste="passcodePaste"
              />
            </div>
          </div>
        </template>
        <div class="flex flex-col items-center">
          <ClientOnly>
            <NuxtTurnstile
              ref="turnstile"
              v-model="turnstileToken"
              :options="{
                'error-callback': (errorCode: string) =>
                  (errorMessage = `Your browser failed bot detection, please try again or contact info@ussif.org and quote error code ${errorCode}.`),
                'appearance': 'interaction-only',
              }"
            />
          </ClientOnly>
        </div>
        <div class="mt-4 flex flex-col gap-4">
          <ClientOnly>
            <UiButton
              v-if="!usePasscode"
              button-type="primary"
              button-theme="dark"
              button-size="large"
              class="w-full"
            >
              <button :disabled="!turnstileToken || pending">
                Login
              </button>
            </UiButton>
            <UiButton
              v-else
              button-type="primary"
              button-theme="dark"
              button-size="large"
              class="w-full"
            >
              <button
                type="button"
                :disabled="!turnstileToken || pending"
                @click="
                  () => {
                    usePasscode = false;
                    errorMessage = '';
                    passcodeSent = '';
                  }
                "
              >
                Go back
              </button>
            </UiButton>
            <UiButton
              v-if="!usePasscode || !passcodeSent"
              button-type="primary"
              button-theme="transparent"
              button-size="large"
              class="w-full"
            >
              <button
                type="button"
                :disabled="!turnstileToken || pending"
                @click="
                  (event) => {
                    if (email) {
                      login(event, true);
                    }
                    else {
                      usePasscode = true;
                    }
                  }
                "
              >
                or email me a passcode
              </button>
            </UiButton>
            <UiButton
              v-if="usePasscode && passcodeSent"
              button-type="primary"
              button-theme="dark"
              button-size="large"
              class="w-full"
            >
              <button
                type="button"
                :disabled="!turnstileToken || pending"
                @click="
                  (event) => {
                    if (checkPasscode()) {
                      login(event, true);
                    }
                  }
                "
              >
                Login
              </button>
            </UiButton>
          </ClientOnly>
        </div>
      </form>
      <div class="mt-10 flex justify-between">
        <div
          v-if="!usePasscode"
          class=""
        >
          <NuxtLink
            to="/reset-password"
            class="font-bold underline"
          >
            Forgot password
          </NuxtLink>
        </div>
        <div class="">
          Not a member?
          <NuxtLink
            to="/join"
            class="font-bold underline"
          >
            Register
          </NuxtLink>
        </div>
      </div>
    </div>
  </UiContainer>
</template>
